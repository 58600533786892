import React, { HTMLProps } from 'react';
import styles from './EventType.module.scss';
import clsx from 'clsx';

import { EventType as CalendarEventType } from '../../../domain/Calendar/CalendarEvent';
import { ReactComponent as DeliveryIcon } from './DeliveryIcon.svg';
import { ReactComponent as DeliveryIconFiltersSelected } from './DeliveryIconFiltersSelected.svg';

import { ReactComponent as ReturnIcon } from './ReturnIcon.svg';
import { ReactComponent as ReturnIconFiltersSelected } from './ReturnIconFiltersSelected.svg';

import { ReactComponent as CarryInIcon } from './CarryInIcon.svg';
import { ReactComponent as CarryInIconFiltersSelected } from './CarryInIconFiltersSelected.svg';
import { ReactComponent as CarryInIconFiltersUnselected } from './CarryInIconFiltersUnselected.svg';

import { ReactComponent as CarryOutIcon } from './CarryOutIcon.svg';
import { ReactComponent as CarryOutIconFiltersSelected } from './CarryOutIconFiltersSelected.svg';
import { ReactComponent as CarryOutIconFiltersUnselected } from './CarryOutIconFiltersUnselected.svg';

import { ReactComponent as ServiceDeliveryIcon } from './ServiceDeliveryIcon.svg';
import { ReactComponent as ServiceDeliveryIconFiltersSelected } from './ServiceDeliveryIconFiltersSelected.svg';

import { ReactComponent as ServiceCarryInIcon } from './ServiceCarryInIcon.svg';
import { ReactComponent as ServiceCarryInIconSelected } from './ServiceCarryInIconSelected.svg';

import { ReactComponent as ServiceCarryOutIcon } from './ServiceCarryOutIcon.svg';
import { ReactComponent as ServiceCarryOutIconSelected } from './ServiceCarryOutIconSelected.svg';

import { ReactComponent as ServiceIcon } from './ServiceIcon.svg';
import { ReactComponent as ServiceIconSelected } from './ServiceIconSelected.svg';

import { ReactComponent as SwitchOutIcon } from './SwitchOutIcon.svg';
import { ReactComponent as SwitchOutIconSelected } from './SwitchOutIconSelected.svg';

import { ReactComponent as StoreEventIcon } from '../../Calendar/EventCard/StoreEventCard/StoreEventIcon.svg';
import { ReactComponent as StoreIconSelected } from './StoreIconSelected.svg';

import { EventTypeNames } from '../../Calendar/NewEvent/EventDetails/EventDetailsForm/EventDetailsForm';

interface TypeProps extends HTMLProps<HTMLDivElement> {
  type: CalendarEventType;
  iconClassName?: string;
  isFilterEvent?: boolean;
  isSelected?: boolean;
}

export const EventType = (props: TypeProps) => {
  const { type, iconClassName, isFilterEvent, isSelected, ...rest } = props;

  if (!type) return null;

  const EventTypeIcon = ({
    [CalendarEventType.Delivery]: DeliveryIcon,
    [CalendarEventType.Return]: ReturnIcon,
    [CalendarEventType.Service]: ServiceIcon,
    [CalendarEventType.CarryIn]: CarryInIcon,
    [CalendarEventType.CarryOut]: CarryOutIcon,
    [CalendarEventType.ServicePickup]: ServiceIcon,
    [CalendarEventType.ServiceDelivery]: ServiceDeliveryIcon,
    [CalendarEventType.ServiceCarryIn]: ServiceCarryInIcon,
    [CalendarEventType.ServiceCarryOut]: ServiceCarryOutIcon,
    [CalendarEventType.SwitchOut]: SwitchOutIcon,
    [CalendarEventType.Store]: StoreEventIcon
  } as Partial<Record<CalendarEventType, any>>)[type];

  const FilterEventTypeIcon = ({
    [CalendarEventType.Delivery]: isSelected
      ? DeliveryIconFiltersSelected
      : DeliveryIcon,
    [CalendarEventType.Return]: isSelected
      ? ReturnIconFiltersSelected
      : ReturnIcon,
    [CalendarEventType.Service]: isSelected ? ServiceIconSelected : ServiceIcon,
    [CalendarEventType.CarryIn]: isSelected
      ? CarryInIconFiltersSelected
      : CarryInIconFiltersUnselected,
    [CalendarEventType.CarryOut]: isSelected
      ? CarryOutIconFiltersSelected
      : CarryOutIconFiltersUnselected,
    [CalendarEventType.ServicePickup]: isSelected
      ? ServiceIconSelected
      : ServiceIcon,
    [CalendarEventType.ServiceDelivery]: isSelected
      ? ServiceDeliveryIconFiltersSelected
      : ServiceDeliveryIcon,
    [CalendarEventType.ServiceCarryIn]: isSelected
      ? ServiceCarryInIconSelected
      : ServiceCarryInIcon,
    [CalendarEventType.ServiceCarryOut]: isSelected
      ? ServiceCarryOutIconSelected
      : ServiceCarryOutIcon,
    [CalendarEventType.SwitchOut]: isSelected
      ? SwitchOutIconSelected
      : SwitchOutIcon,
    [CalendarEventType.Store]: isSelected ? StoreIconSelected : StoreEventIcon
  } as Partial<Record<CalendarEventType, any>>)[type];

  const Icon = isFilterEvent ? FilterEventTypeIcon : EventTypeIcon;

  return (
    <div {...rest}>
      <Icon className={clsx(styles.icon, iconClassName)} />
      {type === CalendarEventType.Store ? 'Store Event' : EventTypeNames[type]}
    </div>
  );
};
