import React, { useContext, useEffect, useRef, useState } from 'react';
import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import { generatePath, useHistory } from 'react-router-dom';
import { Card } from '@rentacenter/racstrap';

import styles from './Calendar.module.scss';

import { CalendarSideModal } from './CalendarSideModal/CalendarSideModal';
import { CalendarToolbar } from './CalendarToolbar/CalendarToolbar';
import { TimeSlotRow } from './TimeSlotRow/TimeSlotRow';
import { EventCard } from './EventCard/EventCard';
import { createTimeslot, TimeSlot } from '../../domain/Calendar/Timeslot';
import { TIME } from '../../utils/time';
import { StoreContext } from '../../context/store/StoreProvider';
import { TimeCursor } from './TimeCursor/TimeCursor';
import { ModalConfirmation } from '../common/ModalConfirmation/ModalConfirmation';
import { EventCompletedReasonModal } from './EventCompletedReasonModal/EventCompletedReasonModal';
import {
  EventsDispatchContext,
  EventsStateContext,
  MappedEvents
} from '../../context/events/EventsProvider';
import { DapRoute } from '../../config/route-config';
import { UpcomingEvents } from './UpcomingEvents/UpcomingEvents';
import { EventsSkeleton } from './EventsSkeleton/EventsSkeleton';
import {
  CalendarEvent,
  EventStatus,
  EventStatusActionType,
  EventType,
  TimeSlotStatus
} from '../../domain/Calendar/CalendarEvent';
import { PrintPreviewModal } from '../common/PrintPreviewModal/PrintPreviewModal';
import { ApiError } from '../common/ApiError/ApiError';
import { CancelToken, CancelTokenSource } from 'axios';
import { getCancelTokenSource } from '../../api/client';
import { EventCancel } from './EventCancel/EventCancel';
import { TimeSlotValue } from './TimeSlotSelector/TimeSlotSelector';
import { EventReopenWithTimeSlots } from './EventReopenWithTimeSlots/EventReopenWithTimeSlots';
import { useEventSource } from './useEventSource';

export interface ActiveTimeSlot {
  timeslot: TimeSlot;
  index: number;
  afterLunch: boolean;
}

export interface CalendarState {
  selectedDate: Date;
}

export interface FetchCalendarInfoProps {
  showLoader?: boolean;
  cancelToken: CancelToken;
}

export const calendarTestId = 'calendarTestId';
export const upcomingEventsTestId = 'upcomingEventsTestId';

export const getConfirmModalContent = (
  eventType?: EventType,
  eventStatusActionType?: EventStatusActionType
) => {
  if (
    eventStatusActionType === EventStatusActionType.Complete &&
    (eventType === EventType.Delivery || eventType === EventType.SwitchOut)
  ) {
    return <>Did the customer sign the Delivery Receipt?</>;
  }

  return (
    <>
      Are you sure you want to <b>{eventStatusActionType}</b> this event?
    </>
  );
};

export const getConfirmModalConfirmButtonText = (
  eventType?: EventType,
  eventStatusActionType?: EventStatusActionType
) => {
  if (
    eventStatusActionType === EventStatusActionType.Complete &&
    (eventType === EventType.Delivery || eventType === EventType.SwitchOut)
  ) {
    return 'Yes';
  }

  return 'Confirm';
};

// TODO: Refractor component to decrease complexity
// eslint-disable-next-line
export const Calendar = () => {
  const history = useHistory();
  const { selectedStore } = useContext(StoreContext);
  const {
    timeSlots,
    isStoreClosed,
    selectedDate,
    events,
    hasApiError,
    filters
  } = useContext(EventsStateContext);

  const {
    fetchTimeSlots,
    setSelectedDate,
    fetchEvents,
    setReloadUpcomingEvents,
    updateEventStatus,
    reopenWithNewTimeSlot,
    unblockTimeslot
  } = useContext(EventsDispatchContext);

  const eventSource = useEventSource();

  const [pending, setPending] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState<boolean>();
  const [autoRefreshIntervalId, setAutoRefreshIntervalId] = useState<
    number | undefined
  >();
  const [viewEvent, setViewEvent] = useState<CalendarEvent>();
  const [eventStatusActionType, setEventStatusActionType] = useState<
    EventStatusActionType
  >();
  const [filteredEvents, setFilteredEvents] = useState<MappedEvents>({});
  const [
    viewStatusChangeConfirmModal,
    setViewStatusChangeConfirmModal
  ] = useState(false);
  const [activeTimeSlot, setActiveTimeSlot] = useState<ActiveTimeSlot>();
  const [printEvent, setPrintEvent] = useState<string>();
  const [isTimeSlotModalVisible, setTimeSlotModalVisibility] = useState<
    boolean
  >(false);

  const activeTimeslotFocused = useRef<boolean>(false);
  const timeSlotView = useRef<HTMLDivElement>(null);
  const initialCallToken = useRef<CancelTokenSource>();
  const confirmModalCancelBtnText =
    eventStatusActionType === EventStatusActionType.Complete &&
    viewEvent?.type === EventType.Return
      ? 'Cancel'
      : 'No';

  useEffect(() => {
    const filterEvent = { ...events };
    Object.entries(filterEvent).forEach(([key, value]) => {
      filterEvent[key] = value.filter(e => {
        if (e.type === EventType.BlockTime) return true;

        if (e.status === EventStatus.Deleted && !filters.showDeletedEvents) {
          return false;
        }

        const hasStatusFilteredEvent =
          filters.EventStatus.length > 0
            ? filters.EventStatus.includes(e.status)
            : true;
        const hasTypeFilteredEvent =
          filters.EventType.length > 0
            ? filters.EventType.includes(e.type)
            : true;
        const hasSourceFilteredEvent =
          filters.EventSource.length > 0
            ? filters.EventSource.includes(e.source)
            : true;

        return (
          hasStatusFilteredEvent &&
          hasTypeFilteredEvent &&
          hasSourceFilteredEvent
        );
      });
    });

    setFilteredEvents(filterEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, filters]);

  const updateActiveTimeslot = () => {
    if (!timeSlots) {
      return;
    }
    const now = new Date();
    let afterLunch = false;
    let foundTimeslot = false;

    for (let index = 0; index < timeSlots.length; index++) {
      const timeslot = timeSlots[index];
      if (isAfter(now, timeslot.startTime) && isBefore(now, timeslot.endTime)) {
        setActiveTimeSlot({ timeslot, index, afterLunch });
        foundTimeslot = true;
        break;
      }

      if (timeslot.lunchBreak) {
        afterLunch = true;
      }
    }

    if (!foundTimeslot) {
      const afterHoursTimeslot = createTimeslot(
        {
          timeSlotId: '-1',
          startTime: format(now, 'H:00:00'),
          endTime: format(now, 'H:00:00'),
          status: TimeSlotStatus.Blocked
        },
        now
      );

      setActiveTimeSlot({
        timeslot: afterHoursTimeslot,
        index: -1,
        afterLunch: true
      });
    }
  };

  const fetchCalendarInfo = (showLoader = true) => {
    if (!selectedStore) return;

    if (initialCallToken.current) {
      initialCallToken.current.cancel();
    }

    initialCallToken.current = getCancelTokenSource();

    showLoader && setEventsLoading(true);
    const promises = [
      fetchTimeSlots(
        selectedStore,
        selectedDate,
        initialCallToken.current.token
      ),
      fetchEvents(selectedStore, selectedDate, initialCallToken.current.token)
    ];

    Promise.all(promises).finally(() => {
      if (showLoader) {
        setEventsLoading(false);
        setAutoRefresh(true);
      }
    });
  };

  useEffect(() => {
    setAutoRefresh(false);
    fetchCalendarInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore, selectedDate]);

  useEffect(
    function maintainActiveTimeslot() {
      updateActiveTimeslot();
      const intervalId = setInterval(updateActiveTimeslot, 1 * TIME.MINUTES);

      return () => {
        clearInterval(intervalId);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeSlots]
  );

  useEffect(
    function focusActiveTimeslot() {
      if (!activeTimeSlot) {
        return;
      }

      if (!activeTimeslotFocused.current) {
        activeTimeslotFocused.current = true;
        timeSlotView.current?.scrollTo({
          top: activeTimeSlot.index * 110
        });
      }
    },
    [activeTimeSlot]
  );

  const handleStatusChange = (eventStatusActionType: EventStatusActionType) => {
    setEventStatusActionType(eventStatusActionType);
    setViewStatusChangeConfirmModal(true);
  };

  const onStatusChangedSuccessfully = () => {
    setViewStatusChangeConfirmModal(false);
    setViewEvent(undefined);
    setEventsLoading(false);
    setTimeSlotModalVisibility(false);
  };

  const onStatusFailedToChange = (status: number) => {
    if (
      eventStatusActionType === EventStatusActionType.Reopen &&
      status === 400
    ) {
      setTimeSlotModalVisibility(true);
      setViewStatusChangeConfirmModal(false);
    }
    setEventsLoading(false);
  };

  const handleStatusChangeConfirm = (reason?: string) => {
    if (!selectedStore || viewEvent === undefined) return;
    setPending(true);

    if (viewEvent.type === EventType.BlockTime) {
      setEventsLoading(true);
      unblockTimeslot(selectedStore, eventSource, viewEvent)
        .then(onStatusChangedSuccessfully)
        .finally(() => {
          setPending(false);
          setEventsLoading(false);
        });
      return;
    }

    if (eventStatusActionType) {
      setEventsLoading(true);
      updateEventStatus(
        viewEvent.eventId,
        selectedStore,
        viewEvent.timeSlot.timeSlotId,
        eventStatusActionType,
        eventSource,
        reason,
        true
      )
        .then(onStatusChangedSuccessfully)
        .catch(onStatusFailedToChange)
        .finally(() => setPending(false));
    }
  };

  useEffect(() => {
    if (autoRefresh) {
      const intervalId = setInterval(
        fetchCalendarInfo,
        3 * TIME.MINUTES,
        false
      );

      setAutoRefreshIntervalId(intervalId);
      return () => {
        clearInterval(intervalId);
      };
    } else if (autoRefreshIntervalId) {
      clearInterval(autoRefreshIntervalId);
      setAutoRefreshIntervalId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefresh]);

  const handleClose = () => {
    setAutoRefresh(true);
    setViewEvent(undefined);
  };

  const handlePrint = (eventId: string) => {
    setViewEvent(undefined);
    setPrintEvent(eventId);
  };

  const handleDateChange = (date: Date) => {
    if (isSameDay(date, new Date())) {
      setReloadUpcomingEvents(true);
    }

    setSelectedDate(date);
    setAutoRefresh(undefined);
  };

  const getDetailsURL = () => {
    const { isRenderedByContainer } = window;
    const path = generatePath(DapRoute.EventDetails, {
      storeId: selectedStore,
      eventId: printEvent
    });

    const suffix = isRenderedByContainer ? '' : '/dap';
    return `${suffix}${path}#fullscreen`;
  };

  const onUpdateTimeSlot = (timeSlot: TimeSlotValue) => {
    if (!timeSlot || !viewEvent || !selectedStore) return;
    setEventsLoading(true);
    setPending(true);

    reopenWithNewTimeSlot(
      viewEvent.eventId,
      selectedStore,
      eventSource,
      viewEvent.timeSlot.timeSlotId,
      timeSlot
    )
      .then(onStatusChangedSuccessfully)
      .finally(() => {
        setPending(false);
      });
  };

  const renderModal = () => {
    if (!viewEvent) return <></>;

    if (isTimeSlotModalVisible) {
      return (
        <EventReopenWithTimeSlots
          onCancel={() => setTimeSlotModalVisibility(false)}
          onConfirm={onUpdateTimeSlot}
          pending={pending}
          timeSlot={{
            timeSlotId: viewEvent.timeSlot.timeSlotId,
            date: new Date(viewEvent.eventDate)
          }}
        />
      );
    }

    if (
      eventStatusActionType === EventStatusActionType.Complete &&
      viewEvent?.type === EventType.Return
    ) {
      return (
        <EventCompletedReasonModal
          open={viewStatusChangeConfirmModal}
          onCancel={() =>
            !eventsLoading && setViewStatusChangeConfirmModal(false)
          }
          onConfirm={handleStatusChangeConfirm}
          pending={pending}
        />
      );
    }

    if (
      eventStatusActionType === EventStatusActionType.Cancel &&
      viewEvent.type !== EventType.BlockTime
    ) {
      return (
        <EventCancel
          open={viewStatusChangeConfirmModal}
          event={viewEvent}
          onCancel={() =>
            !eventsLoading && setViewStatusChangeConfirmModal(false)
          }
          onConfirm={handleStatusChangeConfirm}
          pending={pending}
        />
      );
    }

    if (
      !(
        viewEvent.type === EventType.Return &&
        eventStatusActionType === EventStatusActionType.Complete
      )
    ) {
      return (
        <ModalConfirmation
          open={viewStatusChangeConfirmModal}
          title="Please confirm"
          description={getConfirmModalContent(
            viewEvent.type,
            eventStatusActionType
          )}
          cancelButtonText={confirmModalCancelBtnText}
          confirmButtonText={getConfirmModalConfirmButtonText(
            viewEvent.type,
            eventStatusActionType
          )}
          confirmButtonProps={{ color: 'primary' }}
          loading={eventsLoading}
          onCancel={() =>
            !eventsLoading && setViewStatusChangeConfirmModal(false)
          }
          onConfirm={handleStatusChangeConfirm}
        />
      );
    }
  };

  function renderTimeSlots() {
    if (hasApiError) {
      return (
        <ApiError
          className={styles.apiError}
          text="We are experiencing an internal server problem,
            please refresh the page or select another date"
        />
      );
    }

    if (isStoreClosed) {
      return <div className={styles.storeClosed}>Store Closed</div>;
    }

    return (
      <div className={styles.timeslots} ref={timeSlotView}>
        <TimeCursor
          timeSlots={timeSlots}
          activeTimeSlot={activeTimeSlot}
          selectedDate={selectedDate}
        />
        {timeSlots.map(timeslotInterval => (
          <TimeSlotRow
            key={timeslotInterval.startTime.toString()}
            interval={timeslotInterval}
            activeTimeSlot={activeTimeSlot}
          >
            {eventsLoading && <EventsSkeleton />}
            {!eventsLoading &&
              filteredEvents[timeslotInterval.id]?.map(event => {
                return (
                  <EventCard
                    key={event.eventId}
                    event={event}
                    onClick={() => setViewEvent(event)}
                  />
                );
              })}
          </TimeSlotRow>
        ))}

        <TimeSlotRow
          interval={timeSlots.slice(-1)[0]}
          activeTimeSlot={activeTimeSlot}
          last
        />
      </div>
    );
  }

  return (
    <div className={styles.calenderWrapper}>
      <Card
        data-testid={upcomingEventsTestId}
        className={styles.upcomingEvents}
      >
        <UpcomingEvents
          activeTimeSlot={activeTimeSlot}
          onEventClick={setViewEvent}
          pauseAutoRefresh={!autoRefresh}
        />
      </Card>
      <Card data-testid={calendarTestId} className={styles.calendar}>
        <CalendarToolbar
          eventsLoading={eventsLoading}
          selectedDate={selectedDate}
          onDateChange={(newDate: Date) => handleDateChange(newDate)}
          onCreateEvent={() => history.push(DapRoute.CalendarNewEvent)}
          onShowEvent={event => setViewEvent(event)}
        />

        {viewEvent && (
          <CalendarSideModal
            onClose={handleClose}
            onStatusChange={handleStatusChange}
            onPrint={handlePrint}
            event={viewEvent}
            setAutoRefresh={setAutoRefresh}
          />
        )}
        {printEvent && selectedStore && (
          <PrintPreviewModal
            url={getDetailsURL()}
            onClose={() => setPrintEvent(undefined)}
          />
        )}
        {renderModal()}
        {renderTimeSlots()}
      </Card>
    </div>
  );
};
