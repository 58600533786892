import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Card,
  Input,
  Select,
  SelectOption,
  ToastType,
  useToastActionsContext
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import { format } from 'date-fns';
import { compact, filter, find, has } from 'lodash';
import { AxiosError } from 'axios';

import styles from './EventDetailsForm.module.scss';

import { Footer } from '../../../../layout/footer/Footer';
import { SetStepProp } from '../../NewEvent';
import { BackButton } from '../../BackButton';
import { CancelButton } from '../../CancelButton';
import { Controller, useForm } from 'react-hook-form';
import { StoreContext } from '../../../../../context/store/StoreProvider';
import { createEvent } from '../../../../../api/calendar';
import { CalendarState } from '../../../Calendar';
import { EventType } from '../../../../../domain/Calendar/CalendarEvent';
import { DapRoute } from '../../../../../config/route-config';
import { EventsDispatchContext } from '../../../../../context/events/EventsProvider';
import {
  useNewEventDispatch,
  useNewEventState
} from '../../../../../context/events/NewEventProvider';
import {
  AddressType,
  CustomerAddressWithIndex
} from '../../../../../domain/Customer/Customer';
import { buildOptions } from '../../../../../utils/buildOptions';
import { ModalConfirmation } from '../../../../common/ModalConfirmation/ModalConfirmation';
import { Divider } from '../../Divider';
import { CancelCreate } from '../../CancelCreate';
import {
  getCustomerDetails,
  updateCustomer
} from '../../../../../api/customer';
import { Item } from '../../../../../domain/Customer/Item';
import {
  timeSlotRules,
  TimeSlotSelector
} from '../../../TimeSlotSelector/TimeSlotSelector';
import {
  APIError,
  getErrorMessage
} from '../../../../../utils/errorHandlerService';
import { useEventSource } from '../../../useEventSource';

export const eventDetailsFormId = 'eventDetailsFormId';

export interface EventDetailsFormData {
  readonly address: string;
  readonly eventType: string;
  readonly timeSlot: any;
  readonly coworkersRequired: string;
  readonly description?: string;
  readonly note?: string;
}

export const EventTypeNames: Partial<Record<EventType, string>> = {
  [EventType.CarryIn]: 'Carry In',
  [EventType.CarryOut]: 'Carry Out',
  [EventType.Delivery]: 'Delivery',
  [EventType.Return]: 'Return',
  [EventType.Service]: 'Service',
  [EventType.ServicePickup]: 'Service Pickup',
  [EventType.ServiceDelivery]: 'Service Delivery',
  [EventType.ServiceCarryIn]: 'Service Carry In',
  [EventType.ServiceCarryOut]: 'Service Carry Out',
  [EventType.SwitchOut]: 'Switch Out'
};

export const CoworkersRequired = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5'
};

enum FormFields {
  Address = 'address',
  EventType = 'eventType',
  TimeSlot = 'timeSlot',
  CoworkersRequired = 'coworkersRequired',
  Note = 'note'
}

interface AddressSelectOption extends SelectOption {
  type: string;
}

const buildAddressOptions = (addresses: CustomerAddressWithIndex[]) => {
  const results = filter(addresses, a => has(a, 'addressLine1'));

  return results.map(address => {
    return {
      value: String(address.index),
      label: compact([
        address.addressLine1,
        address.city,
        address.stateCode,
        address.postalCode
      ]).join(', '),
      type: address.addressType
    };
  });
};

export const allIdenticalAddresses = (
  addresses?: AddressSelectOption[]
): boolean => {
  return (
    addresses?.map(a => a.label).every((label, i, arr) => label === arr[0]) ??
    false
  );
};

const requiredErrorMessage = 'This field is required';

export function EventDetailsForm({ setStep }: SetStepProp) {
  const history = useHistory<CalendarState>();
  const { showToast } = useToastActionsContext();
  const { selectedStore } = useContext(StoreContext);
  const { setSelectedDate } = useContext(EventsDispatchContext);
  const { setEventDetails } = useNewEventDispatch();
  const {
    selectCustomer: { selectedCustomer },
    selectedAgreement,
    selectedItems,
    eventDetails
  } = useNewEventState();
  const [pending, setPending] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [cancelCreate, setCancelCreate] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(true);
  const eventSource = useEventSource();

  const [allAddressesWithindex, setAllAddressesWithindex] = useState<
    CustomerAddressWithIndex[]
  >();
  const [primaryAddress, setPrimaryAddress] = useState<AddressSelectOption>();
  const [addressOptions, setAddressOptions] = useState<AddressSelectOption[]>();
  const [hasError, setHasError] = useState(false);

  const {
    control,
    handleSubmit,
    errors,
    clearErrors,
    watch,
    setValue
  } = useForm<EventDetailsFormData>({
    mode: 'onChange',
    defaultValues: eventDetails
  });

  const formData = watch();

  const inventoryIds = selectedItems?.map(
    (selectedItem: Item) => selectedItem.inventoryId
  );

  const onSubmit = (data: EventDetailsFormData) => {
    if (!selectedStore) return;
    setPending(true);

    const payload: any = {
      partyId: selectedCustomer?.partyId,
      agreementId: selectedAgreement?.agreementId,
      inventoryIds: inventoryIds,
      type: data.eventType,
      source: eventSource,
      eventDate: format(data.timeSlot.date, 'MM/dd/yyyy'),
      timeSlot: {
        timeSlotId: data.timeSlot.timeSlotId
      },
      eventInstruction: data.description,
      requiredCoworkers: data.coworkersRequired,
      note: data.note
    };

    createEvent(selectedStore, payload)
      .then(() => {
        showToast(
          ToastType.Success,
          'Your Event has been successfully created!'
        );
        setSelectedDate(data.timeSlot.date);

        history.push(DapRoute.Calendar);
      })
      .catch((error: AxiosError<APIError>) => {
        const errorMessage = getErrorMessage(error.response);
        const message = errorMessage
          ? errorMessage
          : 'Something went wrong when trying to add your Event, please try again!';
        showToast(ToastType.Error, message);
        window.scrollTo(0, 0);
      })
      .finally(() => setPending(false));
  };

  const handleSelection = (value: string) => {
    const addressSelected = find(addressOptions, { value });
    if (addressSelected?.type === AddressType.Primary) return;

    setOpen(true);
    setPrimaryAddress(addressSelected);
  };

  function selectInitialPrimaryAddress() {
    if (!addressOptions) return;

    setValue(
      FormFields.Address,
      addressOptions.find(a => a.type === AddressType.Primary)?.value
    );
  }

  const handleCancelChange = () => {
    selectInitialPrimaryAddress();
    setOpen(false);
  };

  const handleAddressChange = async () => {
    if (!allAddressesWithindex || !primaryAddress || !selectedCustomer) return;

    try {
      const address = allAddressesWithindex[primaryAddress.value];
      const {
        addressLine1,
        addressLine2,
        city,
        postalCode,
        stateCode
      } = address;

      const payLoad = {
        primaryAddress: {
          addressLine1,
          addressLine2,
          city,
          postalCode,
          stateProvinceAbbreviation: stateCode
        }
      };

      await updateCustomer(selectedCustomer.partyId, payLoad);

      const previousPrimaryAddrIndex = allAddressesWithindex.findIndex(
        a => a.addressType === AddressType.Primary
      );

      setAllAddressesWithindex(
        allAddressesWithindex.map((a, index) => {
          if (index === previousPrimaryAddrIndex) {
            return { ...a, addressType: AddressType.Delivery }; // TODO: need client opinion
          } else if (index === parseInt(primaryAddress.value)) {
            return { ...a, addressType: AddressType.Primary };
          } else {
            return a;
          }
        })
      );
    } catch (e) {
      selectInitialPrimaryAddress();

      showToast(
        ToastType.Error,
        "Something went wrong while changing the customer's primary address"
      );
    }

    setOpen(false);
  };

  useEffect(
    function fetchSelectedCustomerDetails() {
      if (!selectedCustomer) return;

      getCustomerDetails(selectedCustomer.partyId)
        .then(customerDetails => {
          setAllAddressesWithindex(
            customerDetails.addresses.map((a, index) => ({ ...a, index }))
          );
        })
        .catch(() => {
          showToast(ToastType.Error, 'Failed to fetch customer details');
        })
        .finally(() => {
          setLoadingAddress(false);
        });
    },
    [selectedCustomer, showToast]
  );

  useEffect(() => {
    if (!selectedStore || !allAddressesWithindex) return;

    const options = buildAddressOptions(allAddressesWithindex);

    setAddressOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAddressesWithindex]);

  useEffect(selectInitialPrimaryAddress, [addressOptions]);

  const handleTimeSlotError = () => setHasError(true);

  return (
    <>
      <Card className={styles.card}>
        <div className={styles.title}>Event Details</div>
        <form
          id={eventDetailsFormId}
          onSubmit={handleSubmit(onSubmit)}
          className={styles.container}
        >
          <div className={styles.row}>
            <div className={clsx(styles.column, styles.half)}>
              <Controller
                control={control}
                name={FormFields.Address}
                rules={{
                  required: requiredErrorMessage
                }}
                render={({ onChange, value, name }) => (
                  <Select
                    name={name}
                    label="Address"
                    placeholder={
                      loadingAddress
                        ? 'Wait, addresses are being fetched'
                        : 'You have no addresses'
                    }
                    options={addressOptions}
                    disabled={
                      loadingAddress || allIdenticalAddresses(addressOptions)
                    }
                    onChange={value => {
                      handleSelection(value);
                      onChange(value);
                    }}
                    required
                    value={value}
                    size="large"
                    isLoading={loadingAddress}
                    errorMessage={errors?.address?.message}
                  />
                )}
              />
            </div>
            <div className={styles.column}>
              <Controller
                control={control}
                name={FormFields.EventType}
                rules={{
                  required: requiredErrorMessage
                }}
                render={({ onChange, value, name }) => (
                  <Select
                    name={name}
                    label="Event Type"
                    placeholder="Please Select"
                    options={buildOptions(
                      EventTypeNames as Record<string, string>
                    )}
                    onChange={onChange}
                    required
                    value={value}
                    size="large"
                    errorMessage={errors?.eventType?.message}
                  />
                )}
              />
            </div>
            <div className={styles.column}>
              <Controller
                control={control}
                name={FormFields.CoworkersRequired}
                rules={{
                  required: requiredErrorMessage
                }}
                render={({ onChange, value, name }) => (
                  <Select
                    name={name}
                    label="Coworkers Required"
                    placeholder="Select No."
                    options={buildOptions(CoworkersRequired)}
                    onChange={onChange}
                    required
                    value={value}
                    size="large"
                    errorMessage={errors?.coworkersRequired?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={clsx(styles.column, styles.full)}>
              <Controller
                control={control}
                name={FormFields.Note}
                rules={{
                  maxLength: {
                    value: 256,
                    message:
                      'The description text is limited to a number of 256 characters.'
                  }
                }}
                render={({ onChange, value, name }, { invalid }) => (
                  <Input
                    name={name}
                    value={value}
                    label="Note"
                    placeholder="Write a note"
                    size="large"
                    invalid={invalid}
                    className={styles.secondColumn}
                    errorMessage={errors?.note?.message}
                    onChange={(e: any) => {
                      clearErrors(FormFields.Note);
                      onChange(e);
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={clsx(styles.column, styles.full)}>
              <Controller
                control={control}
                name={FormFields.TimeSlot}
                required
                rules={timeSlotRules}
                render={({ onChange }) => (
                  <TimeSlotSelector
                    errorMessage={errors?.timeSlot?.message}
                    onChange={onChange}
                    onError={handleTimeSlotError}
                  />
                )}
              />
            </div>
          </div>
          <button
            id="ie11SubmitBtn"
            form={eventDetailsFormId}
            type="submit"
            style={{ display: 'none' }}
          >
            This is needed for IE11
          </button>
        </form>
      </Card>

      <Footer>
        <div>
          <BackButton
            onClick={() => {
              setEventDetails(formData);
              setStep(2);
            }}
            disabled={pending}
          />
          <Divider />
          <CancelButton
            data-testid="cancelEventCreate"
            disabled={pending}
            onClick={() => setCancelCreate(true)}
          />
        </div>
        <div>
          <Button
            data-testid="createEvent"
            color="primary"
            disabled={pending || hasError}
            type="submit"
            onClick={() => {
              document
                .querySelector<HTMLButtonElement>(`#ie11SubmitBtn`)
                ?.click();
            }}
            {...(pending && {
              icon: <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
            })}
          >
            Create Event
          </Button>
        </div>
      </Footer>

      <CancelCreate show={cancelCreate} setShow={setCancelCreate} />

      <ModalConfirmation
        open={open}
        title="Change primary address"
        description="The address you chose will now be the customer’s primary address"
        cancelButtonText="Cancel"
        confirmButtonText="Yes, Change"
        onCancel={handleCancelChange}
        onConfirm={handleAddressChange}
      />
    </>
  );
}
