import React, { HTMLProps } from 'react';
import styles from './EventSource.module.scss';
import clsx from 'clsx';

import { EventSourceType } from '../../../domain/Calendar/CalendarEvent';
import { ReactComponent as Online } from './Online.svg';
import { ReactComponent as Store } from './Store.svg';
import { ReactComponent as Support } from './Support.svg';

interface EventSourceProps extends HTMLProps<HTMLDivElement> {
  source: EventSourceType;
  iconClassName?: string;
}

export const EventSource = (props: EventSourceProps) => {
  const { source, iconClassName, ...rest } = props;

  if (!Object.values(EventSourceType).includes(source)) return null;

  const Icon = {
    [EventSourceType.Store]: Store,
    [EventSourceType.Online]: Online,
    [EventSourceType.Support]: Support
  }[source];

  return (
    <div {...rest}>
      <Icon className={clsx(styles.icon, iconClassName)} />
      {source}
    </div>
  );
};
