import { format } from 'date-fns';

export enum EventStatus {
  ToDo = 'ToDo',
  Completed = 'Completed',
  Canceled = 'Cancelled',
  Deleted = 'Deleted'
}

export const EventStatusNames: Partial<Record<EventStatus, string>> = {
  [EventStatus.ToDo]: 'To Do',
  [EventStatus.Completed]: 'Completed',
  [EventStatus.Canceled]: 'Canceled',
  [EventStatus.Deleted]: 'Deleted'
};

export enum DiliveryCompleteReason {
  AddRemove = 'ADON',
  AttemptedSkip = 'AS',
  ReturnAfterDelivery = 'B',
  CantAfford = 'CANT',
  CustomerChangedMind = 'CCM',
  CorporateLease = 'CL',
  Collection = 'COLL',
  Competition = 'COMP',
  RefundExchange = 'E',
  EventRentalCancelledBeforeDelivery = 'ERC',
  PartialReturn = 'F',
  HardAccount = 'HARD',
  Loaner = 'LOAN',
  Moving = 'MOVE',
  NoLongerNeeds = 'NEED',
  PaymentDefault = 'PAY',
  QualityRejected = 'Q',
  ReturnItems = 'RI',
  ShortTermEventRentalReturn = 'SERR',
  ServiceIssues = 'SI',
  UpgradeDowngradeItem = 'UDI',
  UrgentRequest = 'X',
  RTOConversion = 'Y',
  RTOPickup = 'Z'
}

export const DeliveryCompleteReasonNames: Record<
  DiliveryCompleteReason,
  string
> = {
  [DiliveryCompleteReason.AddRemove]: 'Add or Remove an Item',
  [DiliveryCompleteReason.AttemptedSkip]: 'Attempted Skip',
  [DiliveryCompleteReason.ReturnAfterDelivery]: 'Return After Delivery',
  [DiliveryCompleteReason.CantAfford]: "Can't Afford",
  [DiliveryCompleteReason.CustomerChangedMind]: 'Customer Changed Mind',
  [DiliveryCompleteReason.CorporateLease]: 'Corporate Lease',
  [DiliveryCompleteReason.Collection]: 'Collection',
  [DiliveryCompleteReason.Competition]: 'Competition',
  [DiliveryCompleteReason.RefundExchange]: 'Refund Exchange',
  [DiliveryCompleteReason.EventRentalCancelledBeforeDelivery]:
    'Event Rental - Cancelled Before Delivery',
  [DiliveryCompleteReason.PartialReturn]: 'Partial Return',
  [DiliveryCompleteReason.HardAccount]: 'Hard Account',
  [DiliveryCompleteReason.Loaner]: 'Loaner',
  [DiliveryCompleteReason.Moving]: 'Moving',
  [DiliveryCompleteReason.NoLongerNeeds]: 'No Longer Needs',
  [DiliveryCompleteReason.PaymentDefault]: '1st Payment Default',
  [DiliveryCompleteReason.QualityRejected]: 'Quality Rejected by Client',
  [DiliveryCompleteReason.ReturnItems]: 'Return Items',
  [DiliveryCompleteReason.ShortTermEventRentalReturn]:
    'Short Term Event Rental - Return',
  [DiliveryCompleteReason.ServiceIssues]: 'Service Issues',
  [DiliveryCompleteReason.UpgradeDowngradeItem]: 'Upgrade/Downgrade Item',
  [DiliveryCompleteReason.UrgentRequest]: 'Urgent Request',
  [DiliveryCompleteReason.RTOConversion]: 'RTO Conversion',
  [DiliveryCompleteReason.RTOPickup]: 'RTO Pickup'
};

export const EventPriority: Record<EventStatus, number> = {
  [EventStatus.ToDo]: 1,
  [EventStatus.Completed]: 2,
  [EventStatus.Canceled]: 3,
  [EventStatus.Deleted]: 4
};

export enum EventSourceType {
  Support = 'Support',
  Store = 'Store',
  Online = 'Online'
}

export enum EventStatusActionType {
  Complete = 'complete',
  Cancel = 'cancel',
  CancelRecur = 'cancelRecur',
  Reopen = 'reopen',
  Delete = 'delete'
}

export enum EventType {
  Delivery = 'Delivery',
  Return = 'Return',
  Service = 'Service',
  CarryIn = 'CarryIn',
  CarryOut = 'CarryOut',
  ServicePickup = 'ServicePickup',
  ServiceDelivery = 'ServiceDelivery',
  ServiceCarryIn = 'ServiceCarryIn',
  ServiceCarryOut = 'ServiceCarryOut',
  SwitchOut = 'Switchout',
  Store = 'StoreEvent',
  BlockTime = 'BlockEvent'
}
export enum TimeSlotStatus {
  Available = 'Available',
  Busy = 'Busy',
  Blocked = 'Blocked'
}

export interface RawTimeSlot {
  timeSlotId: string;
  startTime: string;
  endTime: string;
  status?: TimeSlotStatus;
}

export interface CustomerAddress {
  addressId: string;
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface CustomerInfo {
  partyId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address?: CustomerAddress;
}

export interface AgreementInfo {
  agreementId: string;
  agreementNumber: string;
  description: string;
  type: string;
  status: string;
}

interface InventoryItem {
  inventoryId: string;
  description: string;
}

export interface BaseEvent {
  eventId: string;
  eventDate: string;
  instruction: string;
  requiredCoworkers: string;
  status: EventStatus;
  timeSlot: RawTimeSlot;
  type: EventType;
  priority: number;
  recurringAppointmentId?: string;
  storeId?: string;
  startDate?: string;
  endDate?: string;
}

export interface BlockTimeEvent {
  eventId: string;
  eventDate: string;
  instruction: string;
  // TODO:robert.gyori: requiredCoworkers is currently required by the BE API, but it has no meaning for block time event
  // drop this field when API changes
  requiredCoworkers: string;
  timeSlot: RawTimeSlot;
  type: EventType;
  priority: number;
  // TODO: inherit the BaseEvent interface
}

// CustomerEvent
export interface CalendarEvent extends BaseEvent {
  agreement: AgreementInfo;
  customerInformation: CustomerInfo;
  invntories: InventoryItem[];
  noteCount?: number;
  hasStatusChanged?: boolean;
  source: EventSourceType;
  reason?: string;
}

export interface StoreEventRequest {
  eventDate: string;
  eventInstruction: string;
  requiredCoworkers: string;
  type: EventType.Store;
  timeSlot: {
    timeSlotId: string;
  };
  title: string;
}

export interface BlockTimeEventRequest {
  eventDate: string;
  eventInstruction: string;
  type: EventType.BlockTime;
  timeSlot: {
    timeSlotId: string;
  };
}

export interface StoreEvent extends BaseEvent {
  title: string;
}

export const toDo = (arg: CalendarEvent | EventStatus): boolean =>
  EventStatus.ToDo === (typeof arg === 'string' ? arg : arg.status);

export const cancelled = (
  arg: CalendarEvent | StoreEvent | EventStatus
): boolean =>
  EventStatus.Canceled === (typeof arg === 'string' ? arg : arg.status);

export const completed = (arg: CalendarEvent | EventStatus): boolean =>
  EventStatus.Completed === (typeof arg === 'string' ? arg : arg.status);

export const deleted = (arg: CalendarEvent | EventStatus): boolean =>
  EventStatus.Deleted === (typeof arg === 'string' ? arg : arg.status);

export const formatTimeslot = (
  event: CalendarEvent | StoreEvent | BlockTimeEvent,
  startFormat = 'h a ',
  endFormat = ' h a'
): string =>
  `${format(
    new Date(`${event.eventDate} ${event.timeSlot.startTime}`),
    startFormat
  )}-${format(
    new Date(`${event.eventDate} ${event.timeSlot.endTime}`),
    endFormat
  )}`;
